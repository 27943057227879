import { createContext, useContext, useState } from 'react';

const WalletContext = createContext();

export const useWalletContext = () => {
  return useContext(WalletContext);
};

export const WalletProvider = ({ children }) => {
    const [walletConnected, setWalletConnected] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [token, setToken] = useState(null);

  return (
    <WalletContext.Provider value={{ walletConnected, setWalletConnected, token, setToken, walletAddress, setWalletAddress }}>
      {children}
    </WalletContext.Provider>
  );
};
