import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import AudioContext from "../contexts/AudioContext";
import LikeButton from '../components/likeButton';


const NftCard = ({ nft, nfts, nftIndex, currentUser, setCurrentUser }) => {
  const [nftToBeLiked, setNftToBeLiked] = useState(null);
  const [likes, setLikes] = useState([]);
  const [username, setUsername] = useState('');

  const {
    isPlaying,
    setIsPlaying,
    audioRef,
    currentNftId,
    setCurrentNftId,
    nftAudioUrls,
    setNftAudioUrls,
    currentTrackIndex,
    setCurrentTrackIndex,
  } = useContext(AudioContext);

  const getUsernameByIssuer = async () => {
    const nftIssuer = nft.Issuer;
    const response = await fetch(`https://blockchainbeats-herokubackend.herokuapp.com/users/${nftIssuer}`, {
    });
    const data = await response.json();
    setUsername(data.user.username);
  }

  useEffect(() => {
    getUsernameByIssuer();
  }, []);

  const handlePlayButtonClick = () => {
    if (audioRef.current) {
      if (currentNftId === nft._id) {
        if (isPlaying) {
          audioRef.current.pause();
        } else {
          audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
      } else {
        if (isPlaying) {
          audioRef.current.pause();
        }
        audioRef.current.src = nft.audioFile;
        audioRef.current.load(); // Add this line to reload the audio element
        audioRef.current.play();
        setIsPlaying(true);
        setCurrentNftId(nft._id);

      }
    } else {
      const newAudioRef = new Audio(nft.audioFile);
      audioRef.current = newAudioRef;
      newAudioRef.load(); // Add this line to reload the audio element
      newAudioRef.play();
      setIsPlaying(true);
      setCurrentNftId(nft._id);
    }
  };
  
  useEffect(() => {
    const index = nftAudioUrls.findIndex((nft) => nft._id === currentNftId);
    console.log("Current track index: ", index);
    if(index !== -1) {
      setCurrentTrackIndex(index);
    } else if (isPlaying && nft._id === currentNftId) {
      setIsPlaying(false);
    }
}, [currentNftId, nftAudioUrls, setCurrentTrackIndex, setIsPlaying]);


  return (
    <div className="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 p-4 flex flex-col items-start">
      <div className="nft-card bg-white rounded overflow-hidden shadow-lg relative group">
        <div className="nft-image-container relative">
          <Link to={`/nft/${nft._id}`}>
            <img className="w-full object-cover h-48" src={nft.imageFile} alt={nft.Name} />
          </Link>

          <button
            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:opacity-100 transition duration-300 ease-in"
            onClick={handlePlayButtonClick}
            style={{
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
              padding: "10px",
              color: "white",
              fontSize: "24px",
            }}
          >
            {isPlaying && currentNftId === nft._id ? "❚❚" : "▶"}
          </button>
        </div>
      </div>
      <div className="p-2 flex items-center">
        <div className="flex flex-col">
          <Typography variant="subtitle1" className="nft-title">
            {nft.Name}
          </Typography>
          <Typography variant="body1" className="nft-producer">
            {username}
          </Typography>
        </div>
        <div className="flex flex-row">
          <LikeButton
            buttonWidth="50px"
            nftId={nft._id}
            currentUser={currentUser}
            setCurrentUser={setCurrentUser}
            onLikeStatusChange={(newLikeStatus) => {
              if (!currentUser) return;
              if (newLikeStatus) {
                setNftToBeLiked((prevNft) => ({ ...prevNft, likes: [...(prevNft?.likes || []), currentUser] }));
                setLikes((prevLikes) => [...prevLikes, currentUser]);
              } else {
                setNftToBeLiked((prevNft) => ({ ...prevNft, likes: (prevNft?.likes || []).filter((liker) => liker._id !== currentUser._id) }));
                setLikes((prevLikes) => prevLikes.filter((liker) => liker._id !== currentUser._id));
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NftCard;
