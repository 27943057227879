import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    IconButton,
    Paper,
    Slider,
    Typography,
    Grid,
    Button
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { PlayArrow, Pause, VolumeUp, VolumeDown } from "@mui/icons-material";
import AudioContext from "../contexts/AudioContext";
import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";

const SoundBar = ({ }) => {
    const {
        isPlaying,
        setIsPlaying,
        audioRef,
        currentNftId,
        setCurrentNftId,
        currentNftName,
        currentTrackIndex,
        setCurrentTrackIndex,
        nftAudioUrls,
        setNftAudioUrls,
    } = useContext(AudioContext);
    const [audioDuration, setAudioDuration] = useState(0);
    const [sliderValue, setSliderValue] = useState(0);
    const [remainingTime, setRemainingTime] = useState(0);
    const [isSoundBarVisible, setIsSoundBarVisible] = useState(true); // New state to control soundbar visibility
    const [volume, setVolume] = useState(50);

    const matches = useMediaQuery('(max-width:600px)'); 

    useEffect(() => {
        const onLoadedMetadata = () => {
            setAudioDuration(audioRef.current.duration || 0);
        };

        const onTimeUpdate = () => {
            const currentTime = audioRef.current.currentTime;
            setSliderValue(currentTime);
            setRemainingTime(audioDuration - currentTime);
        };

        if (audioRef.current && currentNftId) {
            const ref = audioRef.current; // Capture the current value of audioRef.current
            ref.addEventListener("loadedmetadata", onLoadedMetadata);
            ref.addEventListener("timeupdate", onTimeUpdate);

            return () => {
                ref.removeEventListener("loadedmetadata", onLoadedMetadata);
                ref.removeEventListener("timeupdate", onTimeUpdate);
            };
        }
    }, [audioRef, currentNftId, audioDuration, currentTrackIndex]); // Add currentTrackIndex as a dependency

    const toggleAudioPlayback = () => {
        if (currentNftId) {
            if (isPlaying) {
                audioRef.current.pause();
            } else {
                audioRef.current.play();
            }
            setIsPlaying(!isPlaying);
            console.log('currentTrackIndex: ', currentTrackIndex);
        }
    };

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        console.log('newValue: ', newValue);

        if (audioRef.current && audioRef.current.duration) {
            console.log('audioRef.current.duration: ', audioRef.current.duration);
            audioRef.current.currentTime = newValue;
            console.log('audioRef.current.currentTime: ', audioRef.current.currentTime);
        }
    };

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
        if (audioRef.current) {
            audioRef.current.volume = newValue / 100;
        }
    };

    const handleNextTrack = () => {
        if (isPlaying) {
            audioRef.current.pause();
        }
        let newIndex = currentTrackIndex + 1;
        if (newIndex >= nftAudioUrls.length) {
            newIndex = 0;
        }
        setCurrentNftId(nftAudioUrls[newIndex]._id); // Set the new CurrentNftId here
        setCurrentTrackIndex(newIndex);
    };


    const handlePreviousTrack = () => {
        if (isPlaying) {
            audioRef.current.pause();
        }
        let newIndex = currentTrackIndex - 1;
        if (newIndex < 0) {
            newIndex = nftAudioUrls.length - 1;
        }
        setCurrentNftId(nftAudioUrls[newIndex]._id); // Set the new CurrentNftId here
        setCurrentTrackIndex(newIndex);
    };

    const handleHideSoundBar = () => {
        setIsSoundBarVisible(false);
    };

    const handleShowSoundBar = () => {
        setIsSoundBarVisible(true);
    };

    useEffect(() => {
        if (audioRef.current && nftAudioUrls.length > 0) {
            console.log("Soundbar.js nftAudioUrls[currentTrackIndex]: ", nftAudioUrls[currentTrackIndex]);
            const audioElement = audioRef.current;
    
            // Check if a new source is already being loaded
            if (audioElement.src !== nftAudioUrls[currentTrackIndex]?.audioFile) {
                audioElement.src = nftAudioUrls[currentTrackIndex]?.audioFile;
                audioElement.load();
            }
    
            // Wait for the current source to finish loading before playing
            audioElement.onloadeddata = () => {
                audioElement.play();
                setIsPlaying(true);
            };
        }
    }, [currentTrackIndex, nftAudioUrls, audioRef, setIsPlaying]);
    

    return (
        <>
            {currentNftId && isSoundBarVisible && (
                <Box
                sx={{
                    position: "fixed",
                    bottom: matches ? "10px" : "-10px",
                    left: 0,
                    right: 0,
                    backdropFilter: "blur(10px)", // This will make the background behind the Box blurry
                    backgroundColor: "rgba(255, 255, 255, 0.5)", // This will make the background of the Box semi-transparent
                    padding: matches ? "2% 1%" : "0.2% 1%",
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 10000,
                    boxShadow: "0px -2px 4px rgba(0, 0, 0, 0.1)",
                }}
            >
            <Grid container direction={matches ? "column" : "row"} alignItems="center">
                        <Grid item xs={6}>
                            <Box display="flex" justifyContent="right">
                                <Grid item xs style={{ paddingLeft: '120px', marginBottom: '-50px' }}>
                                    <Typography>{nftAudioUrls[currentTrackIndex]?.Name}</Typography>
                                    <a href={`/nft/${nftAudioUrls[currentTrackIndex]?._id}`}>
                                    <img src={nftAudioUrls[currentTrackIndex]?.imageFile} style={{ width: '100px', height: '100px' }} />
                                    </a>
                                </Grid>

                                <Box sx={{ marginTop: 'auto' }}>
                                    <Grid>
                                        <IconButton onClick={handlePreviousTrack} sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "2rem" }}>
                                            <SkipPreviousIcon fontSize="inherit" />
                                        </IconButton>
                                        <IconButton onClick={toggleAudioPlayback}>
                                            {isPlaying ? <Pause /> : <PlayArrow />}
                                        </IconButton>
                                        <IconButton onClick={handleNextTrack} sx={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "2rem" }}>
                                            <SkipNextIcon fontSize="inherit" />
                                        </IconButton>
                                    </Grid>
                                </Box>
                            </Box>
                            <Box sx={{ width: "100%", mt: 1, marginLeft: "45%", marginRight: "15%" }}>
                                <Slider
                                    min={0}
                                    max={audioDuration}
                                    value={sliderValue}
                                    onChange={handleSliderChange}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginBottom: 1,
                                    }}
                                >
                                    <Typography>
                                        {Math.floor(sliderValue / 60)}:
                                        {("0" + Math.floor(sliderValue % 60)).slice(-2)}
                                    </Typography>
                                    <Typography>
                                        {Math.floor(remainingTime / 60)}:
                                        {("0" + Math.floor(remainingTime % 60)).slice(-2)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container direction="column">
                            <Grid item align="right" sx={{ mt: "-50px" }}>
                                    <Button onClick={handleHideSoundBar}>Hide SoundBar</Button>
                                </Grid>
                                <Grid item sx={{ mt: "20px" }}>
                                    <Box display="flex" justifyContent="flex-end" alignItems="center">

                                        <VolumeDown />
                                        <Slider
                                            min={0}
                                            max={100}
                                            value={volume}
                                            onChange={handleVolumeChange}
                                            sx={{ width: "30%" }}
                                        />
                                        <VolumeUp />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            )}
            {!isSoundBarVisible && isPlaying && (
                <Box
                    sx={{
                        position: "fixed",
                        bottom: 10,
                        right: 10,
                        background: "white",
                        padding: "0.2% 1%",
                        borderRadius: "5px",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Button onClick={handleShowSoundBar}>Show SoundBar</Button>
                </Box>
            )}
        </>
    );
};

export default SoundBar;