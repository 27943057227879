import { createContext, useState, useRef } from "react";

const AudioContext = createContext();

export const AudioProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentNftId, setCurrentNftId] = useState(null);
  const [currentNftName, setCurrentNftName] = useState(null);
  const [nftAudioUrls, setNftAudioUrls] = useState([]);
  const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
  const audioRef = useRef(null); // Initialize with an empty object

  return (
    <AudioContext.Provider
      value={{
        isPlaying,
        setIsPlaying,
        audioRef,
        currentNftId,
        setCurrentNftId,
        currentNftName,
        setCurrentNftName,
        nftAudioUrls,
        setNftAudioUrls,
        currentTrackIndex,
        setCurrentTrackIndex,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export default AudioContext;