import { createContext, useState } from "react";

const UserContext = createContext({
  user: null,
  setUser: () => {},
  refresh: false,
  forceRefresh: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const forceRefresh = () => {
    setRefresh((prev) => !prev);
  };

  return (
    <UserContext.Provider value={{ user, setUser, refresh, forceRefresh }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
