import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Grid, Card, CardContent, CardMedia, Typography, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import AppBar from "@mui/material/AppBar";
import Sidebar from "../components/sidebar";
import Divider from '@mui/material/Divider';
import { useMediaQuery } from '@mui/material';
import { drawerWidth } from '../components/sidebar';
import { useTheme } from '@mui/material/styles';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import { InView } from 'react-intersection-observer';
import NftCard from "../components/NftCard";
import "./marketplace.css"
import defaultProfilePicture from "../images/defaultProfilePicture.png";
import ProducerCard from '../components/producerCard';
import AudioContext from "../contexts/AudioContext";


const FavoritesPage = () => {
    const [nfts, setNfts] = useState([]);
    const [userId, setUserId] = useState(null);
    const [searchInput, setSearchInput] = useState("");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const closedDrawerWidth = isSmallScreen ? theme.spacing(7) : theme.spacing(8);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const { setNftAudioUrls } = useContext(AudioContext);

    const fetchCurrentUser = async () => {
        try {
            const token = localStorage.getItem("access_token");
            if (!token) return;

            if (token) {
                const response = await fetch("https://blockchainbeats-herokubackend.herokuapp.com/users/user", {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const data = await response.json();

                setCurrentUser(data.user);
            }
        } catch (error) {
            console.error("Error fetching current user data:", error);
        }
    };

    useEffect(() => {
        fetchCurrentUser();
    }, []);

    const filterNftsBySearch = (nft) => {
        if (!searchInput) return true;
        const searchString = searchInput.toLowerCase();
        const nameMatch = nft.Name ? nft.Name.toLowerCase().includes(searchString) : false;
        const producerMatch = nft.Producer ? nft.Producer.toLowerCase().includes(searchString) : false;
        return nameMatch || producerMatch;
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem("access_token");

                if (token) {
                    const response = await fetch("https://blockchainbeats-herokubackend.herokuapp.com/NFTs/getLikedNFTs", {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    const data = await response.json();

                    setNfts(data);

                    // Update the nftAudioUrls array in the AudioContext
                    const audioUrls = data.map(nft => nft);
                    setNftAudioUrls(audioUrls);
                    console.log('favorites page audioUrls have been set: ', audioUrls);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const getIndicatorStyle = (selectedIndex) => {
        const baseStyle = {
            width: '100%', // Adjust the width of the indicator
        };
    };

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        console.log('MarketplacePage token: ', token);
        if (token) {
            const decodedToken = JSON.parse(atob(token.split(".")[1]));
            setUserId(decodedToken._id);
        }
    }, []);

    return (
        <Box
            sx={{
                display: "flex",
                backgroundColor: "#f8f8ff",
                minHeight: "100vh",
                "& main": {
                    margin: 0,
                    padding: 0,
                },
            }}
        >
            <Sidebar initialDrawerOpen={drawerOpen} searchInput={searchInput} setSearchInput={setSearchInput} />
            <Box
                className="content-area flex-1 pt-8"
                style={{
                    marginLeft: drawerOpen
                        ? `calc(${drawerWidth}px - 64px)`
                        : `calc(${closedDrawerWidth}px - 48px)`,
                    transition: theme.transitions.create("margin-left", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.enteringScreen,
                    }),
                    background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)',
                }}
                pl={4}
            >

                <Box sx={{ marginTop: "40px" }}>
                    <AppBar position="static" color="inherit" elevation={1} sx={{ boxShadow: "none", background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)' }}>
                        <Tabs value="My Favorite Beats" indicatorColor="primary" centered TabIndicatorProps={{
                            style: {
                                style: getIndicatorStyle(0),
                            },
                        }}>
                            <Tab value="My Favorite Beats" label="My Favorite Beats" sx={{ fontSize: '1em', width: '1200px' }} />


                        </Tabs>
                    </AppBar>
                    <Divider style={{ marginLeft: "16px", marginRight: "16px" }} />
                    <div
                        style={{
                            maxHeight: "calc(100vh - 64px - 40px - 48px)",
                            overflowY: "scroll",
                        }}
                    >
                        <div className="flex flex-wrap justify-center items-center" style={{ padding: "0 4px" }}>
                            {/* {nfts
                .filter(filterNftsBySearch)
                .map((nft, index) => (
                  <NftCard key={nft._id} nft={nft} nfts={nfts} nftIndex={index} />
              ))} */}

                            {nfts
                                .filter(filterNftsBySearch)
                                .map((nft, index) => (
                                    <NftCard key={index} nft={nft} nfts={nfts} nftIndex={index} />
                                ))}

                        </div>
                    </div>
                </Box >
            </Box >
        </Box >
    );
};

export default FavoritesPage;
