import React, { useState, useEffect, useContext, useCallback } from 'react';
import UserContext from '../contexts/UserContext';
import { useWalletContext } from '../contexts/WalletContext';
import ConnectWallet from '../components/ConnectWallet';
import { styled } from '@mui/material/styles';
import { Dialog, DialogTitle, DialogContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import Sidebar from "../components/sidebar";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import AlertDialog from '../components/AlertDialog';
import defaultProfilePicture from "../images/defaultProfilePicture.png";

const Root = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
});

const StyledCard = styled(Card)({
    maxWidth: 600,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2rem',
});

const StyledAvatar = styled(Avatar)({
    width: 150,
    height: 150,
    marginBottom: '1rem',
    alignSelf: 'center',
});

const ProfilePage = () => {
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [bio, setBio] = useState('');
    const [profilePic, setProfilePic] = useState(null);
    const [isEditMode, setIsEditMode] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showDialogAlert, setShowDialogAlert] = React.useState(false);
    const [showUnsavedChangesAlert, setShowUnsavedChangesAlert] = useState(false);
    const [targetLocation, setTargetLocation] = useState(null);
    const [isSaving, setIsSaving] = useState(false);
    const { user, setUser } = useContext(UserContext);
    const { walletConnected, setWalletConnected } = useWalletContext();
    const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);
    const [userUpdated, setUserUpdated] = useState(false);
    const [isFetchComplete, setFetchComplete] = useState(false);
    const [fetchError, setFetchError] = useState(false);

    const navigate = useNavigate();

    const handleWalletConnect = useCallback(() => {
        setShowConnectWalletModal(false);
        setWalletConnected(true);
    }, [setWalletConnected]);

    const handleWalletDisconnect = useCallback(() => {
        setWalletConnected(false);
        setShowConnectWalletModal(false);

    }, [setWalletConnected]);

    const fetchUserDetails = async () => {
        try {
            const token = localStorage.getItem('access_token');

            if (token) {
                const response = await fetch('https://blockchainbeats-herokubackend.herokuapp.com/users/user', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    credentials: 'include',
                });

                if (!response.ok) {
                    throw new Error(response.statusText);
                }
                const { user } = await response.json();

                setUsername(user.username);
                setEmail(user.email);
                setBio(user.bio);
                if (!user.profilePicture) {
                    setProfilePic(defaultProfilePicture);
                } else {
                    setProfilePic(`https://blockchainbeats-herokubackend.herokuapp.com/image/${user.profilePicture.split('/').pop()}`);
                }

                setUser(user);
                setFetchComplete(true);  // Add this line
            }
        } catch (err) {
            console.error(err);
        }
    };

    // function to handle profile picture upload
    const handleProfilePicUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const reader = new FileReader();
        reader.onloadend = () => {
            setProfilePic(reader.result);
            setSelectedImage(file);
        };
        reader.readAsDataURL(file);
        setUnsavedChanges(true);
    };

    // function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSaving(true); // Show loader

        let updatedProfilePicture = null;


        if (selectedImage) {
            try {
                const token = localStorage.getItem('access_token');
                const formData = new FormData();
                formData.append('profilePicture', selectedImage);

                const response = await fetch(
                    'https://blockchainbeats-herokubackend.herokuapp.com/users/profilePicture',
                    {
                        method: 'PUT',
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: formData,
                    },
                );

                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                const responseData = await response.json();

                // Set the updated profile picture URL
                updatedProfilePicture = `https://blockchainbeats-herokubackend.herokuapp.com/image/${responseData.profilePicture.split('/').pop()}`;

                setSelectedImage(null); // Clear the selected image
                setProfilePic(updatedProfilePicture);

                // setUserUpdated(prev => !prev);
                setUser((prevUser) => ({
                    ...prevUser,
                    username,
                    bio,
                    profilePicture: updatedProfilePicture || prevUser.profilePicture,
                }));
                // After updating user information, show the Snackbar
                setSnackbarMessage('Profile information saved successfully');
                setOpenSnackbar(true);
                setUnsavedChanges(false);
                setIsSaving(false); // Hide loader after saving is complete
            } catch (err) {
                console.error(err);
            }
        }

        try {
            const token = localStorage.getItem('access_token');
            const response = await fetch(
                'https://blockchainbeats-herokubackend.herokuapp.com/auth/account',
                {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ username, bio }),
                },
            );

            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 409 && errorData.message === 'Username is already in use') {
                    throw new Error('Username is already taken');
                } else {
                    throw new Error(response.statusText);
                }
            }

            // After updating user information, show the Snackbar
            setSnackbarMessage('Profile information saved successfully');
            setOpenSnackbar(true);
            setUnsavedChanges(false);

            setUser((prevUser) => ({
                ...prevUser,
                username,
                bio,
                profilePicture: updatedProfilePicture || prevUser.profilePicture,
            }));

        } catch (err) {
            // Show a Snackbar with the error message
            setSnackbarMessage(err.message);
            setOpenSnackbar(true);
            console.error(err);
        } finally {
            setIsSaving(false); // Hide loader after saving is complete
            setIsEditMode(false);
        }
    };

    // handle unsaved
    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (unsavedChanges) {
                event.preventDefault();
                event.returnValue = "You have unsaved changes. Are you sure you want to leave without saving?";
            }
        };

        if (unsavedChanges) {
            window.addEventListener("beforeunload", handleBeforeUnload);
        } else {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        }

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, [unsavedChanges]);



    // function to switch between edit mode and view mode
    const handleEdit = () => {
        if (isEditMode) {
            handleCloseEditMode();
        } else if (unsavedChanges) {
            setShowUnsavedChangesAlert(true);
        } else {
            setIsEditMode(!isEditMode);
        }
    };


    // handle close edit mode
    const handleCloseEditMode = () => {
        if (unsavedChanges) {
            setShowUnsavedChangesAlert(true);
            setShowDialogAlert(true);
        } else {
            setIsEditMode(false);

        }
    };

    const handleDiscardChanges = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('access_token');

            const response = await fetch(
                'https://blockchainbeats-herokubackend.herokuapp.com/users/user',
                {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );

            if (!response.ok) {
                throw new Error(response.statusText);
            }

            const responseData = await response.json();
            setProfilePic(responseData.user.profilePicture);
            // After updating user information, show the Snackbar
            setUsername(responseData.user.username);
            setEmail(responseData.user.email);
            setSnackbarMessage('Changes discarded successfully');
            setOpenSnackbar(true);
            setUnsavedChanges(false);
            setIsEditMode(false);
            setShowUnsavedChangesAlert(false);
            setShowDialogAlert(false);
            setUnsavedChanges(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSidebarButtonClick = (targetLocation) => {
        if (unsavedChanges) {
            setShowDialogAlert(true);
            setTargetLocation(targetLocation);
        } else {
            navigate(targetLocation);
        }
    };

    useEffect(() => {
        if (walletConnected) {
            fetchUserDetails();
        }
    }, [walletConnected]);


    useEffect(() => {
        if (walletConnected === false) {
            setShowConnectWalletModal(true);
        }
    }, [walletConnected]);

    return (
        <Root sx={{background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)'}}>
            {/* <Sidebar hideAppBar /> */}
            <Sidebar hideappbar="true" onButtonClick={handleSidebarButtonClick} profilePic={profilePic} />

            {walletConnected ? (
                <>
                    <StyledCard sx={{background: 'linear-gradient(to right, #E8E8E8, #D8D8D8)'}}>
                        <IconButton onClick={handleEdit} sx={{ position: 'relative', top: 40, left: 260 }}>
                            {isEditMode ? <CloseIcon /> : <EditIcon />}
                        </IconButton>


                        <CardContent>
                            <Typography variant="h4" align="center" gutterbottom="true">
                                Profile
                    </Typography>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...(isEditMode ? { justifyContent: 'flex-start' } : {}) }}>
                                {
                                                    fetchError
                                                        ? <div>Error loading profile picture</div>
                                                        : !isFetchComplete
                                                            ? <CircularProgress />
                                                            : <StyledAvatar src={profilePic} />

                                                }
                            </div>

                            {isEditMode ? (
                                <div>
                                    <Button variant="contained" component="label" sx={{ marginBottom: '1rem' }}>
                                        Upload Profile Picture
                            <input type="file" hidden onChange={handleProfilePicUpload} accept="image/*" />
                                    </Button>
                                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                        <TextField
                                            label="Username"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            value={username}
                                            onChange={(e) => {
                                                setUsername(e.target.value);
                                                setUnsavedChanges(true);
                                            }} />
                                        <TextField
                                            label="Bio"
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            multiline
                                            rows={4}
                                            value={bio}
                                            onChange={(e) => {
                                                setBio(e.target.value);
                                                setUnsavedChanges(true);
                                            }} />
                                        <Button variant="contained" fullWidth type="submit" startIcon={isSaving ? <CircularProgress size={24} /> : <SaveIcon />}>
                                            Save
                                </Button>
                                    </form>
                                </div>
                            ) : (
                                    <div>
                                        <Typography variant="h6" align="center" gutterbottom="true">
                                            {username}
                                        </Typography>
                                        <Typography variant="body1" align="center" gutterbottom="true">
                                            {email}
                                        </Typography>
                                        <Typography variant="body1" align="center" gutterbottom="true">
                                            {bio}
                                        </Typography>
                                        <IconButton onClick={handleEdit} sx={{ position: 'absolute', top: 20, right: 20 }}>
                                            <EditIcon />
                                        </IconButton>
                                    </div>
                                )}
                            <Typography variant="body2" align="center" style={{ marginTop: '1rem' }}>
                                <Link href="#" color="primary">
                                    Account settings
                        </Link>{' '}
                        |{' '}
                                <Link href="#" color="primary">
                                    Privacy settings
                        </Link>{' '}
                        |{' '}
                                <Link href="#" color="primary">
                                    Login history
                        </Link>
                            </Typography>
                        </CardContent>
                    </StyledCard>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={4000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                    {showUnsavedChangesAlert && (
                        <AlertDialog
                            open={showDialogAlert}
                            onClose={() => setShowDialogAlert(false)}
                            title="Unsaved Changes"
                            message="You have unsaved changes. Are you sure you want to discard them?"
                            cancelText="Cancel"
                            confirmText="Discard"
                            onConfirm={handleDiscardChanges}
                            onCancel={() => setShowDialogAlert(false)}
                            targetLocation={targetLocation}
                        />

                    )}
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isSaving}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                </>
            ) : (
                    <Dialog
                        open={showConnectWalletModal}
                        onClose={() => setShowConnectWalletModal(false)}
                        fullWidth
                        maxWidth="sm"
                    >
                        <DialogTitle>Connect Wallet to Mint NFT</DialogTitle>
                        <DialogContent>
                            <ConnectWallet
                                onWalletConnected={handleWalletConnect}
                                onWalletDisconnected={handleWalletDisconnect}
                            />
                        </DialogContent>
                    </Dialog>
                )}
        </Root>
    );
};

export default ProfilePage;