import React, { createContext, useReducer, useEffect } from 'react';
import jwtDecode from 'jwt-decode';

export const AuthContext = createContext();

const initialState = {
  isAuthenticated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_AUTH':
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    default:
      return state;
  }
};

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
  
    useEffect(() => {
      dispatch({
        type: 'SET_AUTH',
        payload: isLoggedIn(),
      });
    }, []);
  
    return (
      <AuthContext.Provider value={{ ...state, dispatch }}>
        {children}
      </AuthContext.Provider>
    );
  };

  export const isLoggedIn = () => {
    const token = localStorage.getItem('access_token');
    if (!token) {
      return false;
    }
  
    try {
      const { exp } = jwtDecode(token);
      return Date.now() < exp * 1000;
    } catch (error) {
      console.error('Invalid token specified:', error);
      return false;
    }
  };
  