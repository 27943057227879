import React, { useState, useEffect, useCallback, useContext } from 'react';
import jwt_decode from "jwt-decode";
import { Button, Typography, Snackbar, CircularProgress } from '@mui/material';
import { AuthContext } from '../contexts/AuthContext';
import { useWalletContext } from '../contexts/WalletContext';
import axios from 'axios';

const ConnectWallet = ({ onWalletConnected, onWalletDisconnected }) => {
  const { dispatch } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [qrCodeImageUrl, setQrCodeImageUrl] = React.useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [uuid, setUUID] = useState('');
  const [initialLoading, setInitialLoading] = useState(true);
  const [waitingForScan, setWaitingForScan] = useState(false);
  const [qrCodeKey, setQrCodeKey] = useState(Date.now());

  const { walletConnected, setWalletConnected, setToken, walletAddress, setWalletAddress } = useWalletContext(); // set walletConnected state and JWT token in wallet context for authentication purposes

  const handleLogout = async () => {
    console.log('Removing token from local storage');
  
    await localStorage.removeItem('access_token');
    console.log('token removed from local storage');
    const checkToken = localStorage.getItem('access_token');
    setWalletConnected(false);
    setWalletAddress('');  // Add this line
    dispatch({ type: 'SET_AUTH', payload: false }); // Update the authentication state
  
    // Display a success message
    setSnackbarMessage('Wallet disconnected successfully');
    setSnackbarOpen(true);
  };

  

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const resetQRCode = () => {
    setQrCodeUrl('');
    setQrCodeImageUrl(null);
  };

  // Add a new function to fetch user details
  // Wrap the fetchUserDetails function in useCallback
  const fetchUserDetails = useCallback(async () => {
    try {
      const token = localStorage.getItem('access_token');

      // Verify token is not expired
      if (token) {
        const jwtDecoded = jwt_decode(token);
        if (jwtDecoded.exp < Date.now() / 1000) {
          // Token is expired, remove it and set walletConnected to false
          localStorage.removeItem('access_token');
          setWalletConnected(false);
        } else {
          // Token is not expired
          // Fetch the user data and set walletConnected to true
          const response = await axios.get(
            'https://blockchainbeats-herokubackend.herokuapp.com/users/user',
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (response.data && response.data.user) {
            const newWalletAddress = response.data.user.classicAddress;
            const profilePic = response.data.user.profilePicture;
            console.log('connect wallets profilePic: ', profilePic);

            setWalletAddress(newWalletAddress);
            // setWalletConnected(true);
          }
        }
      }
    } catch (error) {
      console.error('Error fetching user details:', error);
      setWalletConnected(false);
    } finally {
      setInitialLoading(false);
    }
  }, [setWalletConnected, walletAddress]);


  async function pollPayloadStatus(uuid) {
    try {
      const response = await axios.get(
        `https://blockchainbeats-herokubackend.herokuapp.com/auth/api/get-payload-status/${uuid}`
      );
      // Check the payload status
      if (response.data && response.data.meta) {
        if (response.data.meta.signed) {
          // console.log('Payload resolved:', response.data);
          setWalletConnected(true);
          // Set JWT
          const token = await getJWT(uuid);
          localStorage.setItem('access_token', token);
          setToken(token);
          // Display success message
          setSnackbarMessage('Wallet connected successfully');
          setSnackbarOpen(true);
          setWaitingForScan(false);
          // Fetch user details when the wallet is connected
          await fetchUserDetails();
          if (onWalletConnected) {
            onWalletConnected();
          }
        } else if (response.data.meta.cancelled) {
          // console.log('Payload rejected:', response.data);
          setWalletConnected(false);
          // Display rejection message
          setSnackbarMessage('Wallet connection rejected');
          setSnackbarOpen(true);
          setWaitingForScan(false);
          // Reset QR codes
          resetQRCode();
        } else if (response.data.meta.resolved && !response.data.meta.signed) {
          // console.log('User rejected the transaction:', response.data);
          setWalletConnected(false);
          // Display rejection message
          setSnackbarMessage('User closed the app without signing the transaction');
          setSnackbarOpen(true);
          // Set waitingForScan to false
          setWaitingForScan(false);
          // Reset QR codes
          resetQRCode();
        } else {
          // Keep polling
          setTimeout(() => pollPayloadStatus(uuid), 2000);
        }
      } else {
        // Keep polling
        setTimeout(() => pollPayloadStatus(uuid), 2000);
      }
    } catch (error) {
      console.error('Error polling payload status:', error);
      setWalletConnected(false);
    }
  }

  async function getJWT(uuid) {
    try {
      // Send a GET request to the /api/getSignedPayloadJWT/:uuid endpoint
      const response = await axios.get(
        `https://blockchainbeats-herokubackend.herokuapp.com/auth/api/getSignedPayloadJWT/${uuid}`
      );
      // Return the token from the response data
      const token = response.data.token;
      return token;
    } catch (error) {
      console.error('Error:', error);
    }
  }

  const connectWallet = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        'https://blockchainbeats-herokubackend.herokuapp.com/auth/connect-wallet',
        {},
      );

      console.log('connectwallet response: ', response);

      const subscription = response.data.subscription;
      setQrCodeUrl(subscription.payload.qrCodeUrl);

      // Set the QR code image URL in a callback function
      setQrCodeImageUrl((prevState) => {
        const newQrCodeImageUrl = `${subscription.created.refs.qr_png}?cache_bust=${Date.now()}`;
        if (prevState !== newQrCodeImageUrl) {
          return newQrCodeImageUrl;
        }
        return prevState;
      });
      setLoading(false);

      // Set waitingForScan to true
      setWaitingForScan(true);
      // Start polling for payload status
      pollPayloadStatus(subscription.created.uuid);
      setUUID(subscription.created.uuid);

    } catch (error) {
      setLoading(false);
      console.error('Error connecting wallet:', error);
    }
  };

  // Add a useEffect hook to fetch user details when the component mounts
  useEffect(() => {
    async function checkExistingWallet() {
      await fetchUserDetails();
      if (walletAddress) {
        setWalletConnected(true);
      }
    }
  
    checkExistingWallet();
  }, [walletAddress, fetchUserDetails, setWalletConnected, onWalletConnected, walletConnected]);  
  

  useEffect(() => {
    console.log('walletConnected state changed, current state: ', walletConnected);
  }, [walletConnected]);
  


  const walletTitle = () => {
    if (waitingForScan) {
      return 'Scan the QR Code';
    } else if (walletConnected === null) {
      return 'Connect your XUMM Wallet';
    } else if (walletConnected) {
      return 'Wallet Connected Successfully';
    } else {
      return 'Connect to your XUMM Wallet';
    }
  };


  if (initialLoading) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CircularProgress/>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" gutterbottom="true">
        {walletTitle()}
      </Typography>
      {walletConnected && (
        <Typography variant="subtitle1" gutterbottom="true">
          Wallet Address: {walletAddress}
        </Typography>
      )}
      {!walletConnected && !qrCodeImageUrl && (
        <Button
          variant="contained"
          color="primary"
          onClick={connectWallet}
          disabled={loading}
        >
          {loading ? <CircularProgress/> : 'Connect Wallet'}
        </Button>
      )}
      {walletConnected && (
        <Button
          variant="contained"
          color="secondary"
          onClick={handleLogout}
          disabled={loading}
        >
          {loading ? <CircularProgress/> : 'Disconnect Wallet'}
        </Button>
      )}

      {!walletConnected && qrCodeImageUrl && waitingForScan && (
        <img src={qrCodeImageUrl} alt="QR Code" key={qrCodeKey} />
      )}



      {waitingForScan && (
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <CircularProgress size={24} />
          <Typography variant="body1" style={{ marginLeft: '10px' }}>
            Waiting for you to scan the SignIn request with the XUMM app.
    </Typography>
        </div>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
        action={
          <React.Fragment>
            <Button color="secondary" size="small" onClick={handleSnackbarClose}>
              Close
      </Button>
          </React.Fragment>
        }
      />
    </div>
  );
};

export default ConnectWallet;