import React, { useState, useEffect } from 'react';
import { Box, Typography, Avatar, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import { Link } from 'react-router-dom';
import defaultProfilePicture from "../images/defaultProfilePicture.png";
import FollowButton from './followButton';

const FollowerItem = ({ user, currentUser, setCurrentUser, closePopup }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [following, setFollowing] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        if (currentUser && currentUser.following) {
            const userIds = currentUser.following.map(followingUser => followingUser._id);
            setFollowing(userIds.includes(user._id));
            localStorage.setItem(`following-${user._id}`, JSON.stringify(userIds.includes(user._id)));
            console.log('FollowerItem setCurrentUser:', setCurrentUser);

        } else if (token && localStorage.getItem(`following-${user._id}`)) {
            setFollowing(JSON.parse(localStorage.getItem(`following-${user._id}`)));
        }
    }, [currentUser, user._id]);


    return (
        <>
            <Dialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Log in required"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Please log in or register to follow users.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)} color="primary">
                        Cancel
                    </Button>
                    <Link to="/register">
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                            autoFocus
                        >
                            Register
                        </Button>
                    </Link>
                    <Link to="/login">
                        <Button
                            onClick={() => {
                                setOpenDialog(false);
                            }}
                            color="primary"
                        >
                            Log in
                        </Button>
                    </Link>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
                <Link
                    key={user._id}
                    to={`/profile-detail/${user._id}`}
                    style={{ textDecoration: 'none' }}
                    onClick={closePopup}
                >
                    <Avatar
                        src={user.profilePicture || defaultProfilePicture}
                        alt={user.username}
                        sx={{ marginRight: 2 }}
                    />
                </Link>
                <Link
                    key={user._id}
                    to={`/profile-detail/${user._id}`}
                    style={{ textDecoration: 'none' }}
                    onClick={closePopup}
                >
                    <Typography variant="body1" sx={{ marginRight: 5 }}>{user.username}</Typography>
                </Link>

                <Box sx={{ flexGrow: 1 }} />
                <FollowButton
                    buttonWidth="100px"
                    producerId={user._id}
                    currentUser={currentUser}
                    setCurrentUser={setCurrentUser}
                    onFollowStatusChange={(newFollowingStatus) => setFollowing(newFollowingStatus)}
                />
            </Box>
        </>
    );
};

export default FollowerItem;
