import React, { useState, useEffect } from 'react';
import { Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FollowButton from './followButton';
import './producerCard.css';

import defaultProfilePicture from "../images/defaultProfilePicture.png";

const ProducerCard = ({ producer, currentUser, setCurrentUser }) => {
    const [followersCount, setFollowersCount] = useState(producer.followers?.length || 0);
    const [isLoading, setIsLoading] = useState(false);

    const handleFollowStatusChange = (newStatus) => {
        setFollowersCount((prevCount) => prevCount + (newStatus ? 1 : -1));
    };

    const handleButtonClick = () => {
        setIsLoading(true);

        // Simulating an asynchronous API call
        setTimeout(() => {
            // Update the follow status here

            setIsLoading(false);
        }, 1000);
    };

    console.log('producer: ', producer);
    return (
        <>
            <Grid item key={producer._id} xs={12} sm={6} md={4} lg={3} xl={2}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div className="producer-image-wrapper">
                        <div style={{
                            width: '150px',
                            height: '150px',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            border: '2px solid #048F31' // add a 5px green border
                        }}>
                            <Link key={producer._id} to={`/profile-detail/${producer._id}`} style={{ textDecoration: 'none' }}>
                                <img
                                    src={producer.profilePicture || defaultProfilePicture}
                                    alt={producer.username}
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        objectFit: "cover"
                                    }}
                                />
                            </Link>
                        </div>
                    </div>
                    <Typography variant="h6" align="center">
                        {producer.username}
                    </Typography>
                    {producer && (
                        <Typography variant="body2" align="center">
                            {followersCount} {followersCount === 1 ? 'follower' : 'followers'}
                        </Typography>
                    )}
                    <FollowButton
                        producerId={producer._id}
                        currentUser={currentUser}
                        setCurrentUser={setCurrentUser}
                        onFollowStatusChange={handleFollowStatusChange}
                        onClick={handleButtonClick}
                        isLoading={isLoading}
                    />
                </div>
            </Grid>
        </>
    );
};

export default ProducerCard;
