import React, { useState, useCallback } from 'react';
import { Button, Typography, Snackbar, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useWalletContext } from '../contexts/WalletContext';
import axios from 'axios';

const AcceptOffer = ({ onAcceptMade, onAcceptFailed, nftId, nftName, offer, price }) => {
    const { walletConnected, setWalletConnected } = useWalletContext();
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [unsignedAcceptSuccessful, setUnsignedAcceptSuccessful] = useState(false);
    const [transactionSuccessful, setTransactionSuccessful] = useState(false);
    const [transactionRejected, setTransactionRejected] = useState(false);


    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleAcceptOffer = async (NFTokenID, offerId) => {
        setLoading(true);
        try {
            const token = localStorage.getItem('access_token');
            const response = await axios.post(
                'https://blockchainbeats-herokubackend.herokuapp.com/nfts/acceptBuyOffer',
                {NFTokenID: NFTokenID,
                offerId: offerId},
        {
            headers: { Authorization: `Bearer ${token}` },
        }
      );

if (response.status === 201) {
    const payloadId = response.data.uuid.created?.uuid; // assuming the payload ID is returned in the response
    // Start polling for payload status
    pollPayloadStatus(payloadId);

    // Offer made successfully
    setUnsignedAcceptSuccessful(true);
    if (onAcceptMade) {
        onAcceptMade();
    }

} else {
    // Offer failed
    setSnackbarMessage('Accept Offer Failed');
    setUnsignedAcceptSuccessful(false);
    setSnackbarOpen(true);
    if (onAcceptFailed) {
        onAcceptFailed();
    }
}
    } catch (error) {
    console.error('Error Accepting offer:', error);
    setSnackbarMessage('Error Accepting offer');
    setUnsignedAcceptSuccessful(false);
    setSnackbarOpen(true);
    if (onAcceptFailed) {
        onAcceptFailed();
    }
} finally {
    setLoading(false);
}
  };

async function pollPayloadStatus(uuid) {
    try {
        const response = await axios.get(
            `https://blockchainbeats-herokubackend.herokuapp.com/auth/api/get-payload-status/${uuid}`
        );
        // Check the payload status
        if (response.data && response.data.meta) {
            if (response.data.meta.signed) {
                // Offer made successfully
                setSnackbarMessage('Accepted Offer successfully');
                setUnsignedAcceptSuccessful(false);
                setTransactionSuccessful(true);
                setSnackbarOpen(true);
                if (onAcceptMade) {
                    onAcceptMade();
                }
            } else if (response.data.meta.cancelled) {
                // Offer rejected
                setSnackbarMessage('Accept rejected');
                setTransactionRejected(true);
                setUnsignedAcceptSuccessful(false);
                setSnackbarOpen(true);
            } else if (response.data.meta.resolved && !response.data.meta.signed) {
                // User closed the app without signing the transaction
                setUnsignedAcceptSuccessful(false);
                setTransactionRejected(true);
                setSnackbarMessage('User rejected to Accept Offer');
                setSnackbarOpen(true);
            } else {
                // Keep polling
                setTimeout(() => pollPayloadStatus(uuid), 2000);
            }
        } else {
            // Keep polling
            setTimeout(() => pollPayloadStatus(uuid), 2000);
        }
    } catch (error) {
        console.error('Error polling payload status:', error);
    }
}

const [NFTokenID, setNFTokenID] = useState(nftId);
const [offerId, setOfferId] = useState(offer);
const [offerPrice, setOfferPrice] = useState(price);

return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {unsignedAcceptSuccessful ? (
            <React.Fragment>
                <CircularProgress size={24} />
                <Typography variant="body1" style={{ marginLeft: '10px' }}>
                    Waiting for you to sign the transaction request with the XUMM app.
          </Typography>
            </React.Fragment>
        ) : (transactionSuccessful ? (
            <>
                <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
                <Typography variant="h4" gutterbottom="true">
                    Offer Accepted Successfully
          </Typography>
            </>
        ) : (transactionRejected ? (
            <>
                <CancelIcon style={{ fontSize: 50, color: 'red' }} />
                <Typography variant="h4" gutterbottom="true">
                    Accept Offer Rejected
          </Typography>
            </>
        ) : (
                <React.Fragment>
                    <Typography variant="h4" gutterbottom="true">
                        Accept Offer <br/> {offerPrice} XRP
          </Typography>
                    <input
                        value={nftName}
                        onChange={e => setNFTokenID(e.target.value)}
                        placeholder={nftName}
                        disabled
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleAcceptOffer(NFTokenID, offerId)}
                        disabled={loading || !walletConnected}
                    >
                        {loading ? <CircularProgress/> : 'Accept Offer'}
                    </Button>
                </React.Fragment>
            )))}

        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            message={snackbarMessage}
            action={
                <React.Fragment>
                    <Button color="secondary" size="small" onClick={handleSnackbarClose}>
                        Close
            </Button>
                </React.Fragment>
            }
        />
    </div>
);
};

export default AcceptOffer;
