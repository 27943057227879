import React, { useState, useEffect, useCallback } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import greenFlameFilled from '../images/likeButton/greenFlameFilled.png'
import emptyFlame from '../images/likeButton/emptyFlame.png'
import ConnectWallet from '../components/ConnectWallet';
import { useWalletContext } from '../contexts/WalletContext';

import "./likeButton.css"

const LikeButton = (props) => {
  const [liked, setLiked] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const { walletConnected, setWalletConnected } = useWalletContext();
  const [openDialog, setOpenDialog] = useState(false);
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false);

  const { buttonWidth, nftId, onLikeStatusChange } = props;

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (currentUser && currentUser.likedNfts) {
      const likedNftIds = currentUser.likedNfts.map(likedNft => likedNft._id);
      setLiked(likedNftIds.includes(nftId));
      localStorage.setItem(`liked-${nftId}`, JSON.stringify(likedNftIds.includes(nftId)));
    } else if (token && localStorage.getItem(`liked-${nftId}`)) {
      setLiked(JSON.parse(localStorage.getItem(`liked-${nftId}`)));
    }
  }, [currentUser, nftId]);

  const fetchCurrentUser = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) return;

      const response = await fetch("https://blockchainbeats-herokubackend.herokuapp.com/users/user", {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setCurrentUser(data.user);
    } catch (error) {
      console.error("Error fetching current user data:", error);
    }
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const handleButtonClick = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      setOpenDialog(true);
      setLiked(JSON.parse(localStorage.getItem(`liked-${nftId}`)) || false);
    } else {
      handleLike();
    }
  };
  

  const handleWalletConnect = useCallback(() => {
    setShowConnectWalletModal(false);
    setWalletConnected(true);
    console.log('handleWalletConnect called');
  }, [setWalletConnected]);

  const handleWalletDisconnect = useCallback(() => {
    setWalletConnected(false);
    setShowConnectWalletModal(false);

  }, [setWalletConnected]);


  const handleLike = async () => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) return;

      const response = await fetch(`https://blockchainbeats-herokubackend.herokuapp.com/nfts/like/${nftId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          action: liked ? 'unlike' : 'like',
        }),
      });

      console.log('response.ok: ', response);

      if (response.ok) {
        const data = await response.json();
        setCurrentUser(data.user);
        setLiked(!liked);
        localStorage.setItem(`liked-${nftId}`, !liked);
        onLikeStatusChange(!liked); // Call the callback function
      } else {
        throw new Error('Error liking or unliking NFT');
      }
    } catch (error) {
      console.error("Error liking or unliking NFT:", error);
    }
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Wallet Connection Required"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please Connect your Wallet to like NFTs.
                    </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
                    </Button>
          <Button
            onClick={() => {
              setOpenDialog(false);
              setShowConnectWalletModal(true);
            }}
            color="primary"
            autoFocus
          >
            Connect
                          </Button>
        </DialogActions>
      </Dialog>
      <IconButton
        className={liked ? "liked-button" : "unliked-button"}
        onClick={handleButtonClick}
        style={{ width: buttonWidth, height: buttonWidth }}
      >
        {liked ? <img src={greenFlameFilled} /> : <img src={emptyFlame} />}
      </IconButton>
      <Dialog
        open={showConnectWalletModal}
        onClose={() => setShowConnectWalletModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Connect Wallet to Mint NFT</DialogTitle>
        <DialogContent>
          <ConnectWallet
            onWalletConnected={handleWalletConnect}
            onWalletDisconnected={handleWalletDisconnect}
          />
        </DialogContent>
      </Dialog>

    </>
  );
};

export default LikeButton;      